.page404 {
    height: Min(100vh, 960px);
	min-height: 590px;
    position: relative;

	display: grid;
	place-items: center;

	@media (max-width: 1100px) {
		height: Min(100vh, 738px);
	}
	.wrapper {
		max-width: 488px;
		text-align: center;

		@media (max-height: 750px) {
			translate: 0 65px;
		}
		@media (max-width: 1100px) {
			margin-inline: 20px;
			translate: 0 30px !important;
		}
	}
	&--title {
		color: #f5f5f5;
		font-family: 'Rex Bold', sans-serif;
		font-size: 120px;
		font-weight: 700;
		line-height: 65px;
		letter-spacing: -0.02em;
		width: fit-content;
    	margin-inline: auto;

		@media (max-width: 1100px) {
			font-size: 107.78px;
			line-height: 58.38px;
		}
		strong {
			display: block;
			color: #FAD224;
			translate: 23px 16px;
		}
	}
	&--sub-title {
		color: #f5f5f5;
		font-family: 'Raleway', sans-serif;
		font-size: 35px;
		font-weight: 700;
		line-height: 50px;

		display: block;
		margin-block: 60px 20px;
		margin-inline: auto;
		max-width: 400px;

		@media (max-height: 750px) {
			margin-block: 7vh 20px;
		}
		@media (max-width: 1100px) {
			font-size: 25px;
			line-height: 36px;
			margin-block: 44px 20px !important;
		}
	}
	p { color: #f5f5f5; }
	.main-btn {
		margin-inline: auto;
		margin-top: 60px;

		@media (max-height: 750px) {
			margin-top: 7vh;
		}
		@media (max-width: 1100px) {
			width: 100%;
			margin-top: 44px;
		}
	}
}